.bgFill {
  width: 100%;
  height: 100vh;
}

.product-highlight {
  display: block;
  float: left;
  border: 1px solid rgb(79, 155, 157);
  max-height: 155px;
  width: 163px;
  font-size: 10px;
  text-align: center;
  padding-top: 3px;
  overflow: hidden;
  margin: 5px;
}

.dropshadowLighter {
  -moz-box-shadow: 0px 2px 15px rgba(120, 172, 26, 0.2);
  -webkit-box-shadow: 0px 2px 15px rgba(120, 172, 26, 0.2);
  box-shadow: 0px 2px 15px rgba(120, 172, 52, 0.2);
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow: hidden;
}

.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
  overflow: hidden;
}

.Bbutton {
  margin: 0;
  padding: 0;
}
.Bbutton a {
  //margin: 10px 0 0px 0;
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 10px 0 0 0;
  height: 28px;
  background: red;
  width: 142px;
  height: 48px;
  border: 0;
  color: white;
  border-radius: 25px;
  font-weight: bold;
}
.Bbutton a:hover {
  background: blue;
  color: white;
}

.vhsim_data_list li {
  color: black;
  line-height: 26px;
  font-size: 13px;
  list-style: square;
  color: #1178a9;
}
